/* global $, prestashop */
$(document).ready(() => {
  const $unavailableShippingWrapper = $('#unavailable_shipping');

  if (0 === $unavailableShippingWrapper.length) {
    return;
  }

  updateCartActions();

  prestashop.on('updateCart', (e) => {
    if ('skip_unavailable_shipping_refresh' in e) {
      return;
    }

    const $form = $unavailableShippingWrapper.find('form');

    $.ajax({
      type: 'POST',
      url: $unavailableShippingWrapper.data('refresh-url'),
      data: $form.length ? $form.serialize() : null
    })
      .then(response => {
        const content = $(`<span>${response}</span>`)
          .find('#unavailable_shipping')
          .html();

        $unavailableShippingWrapper.html(content);
      });
  });

  prestashop.on('updatedCart', updateCartActions);

  $(document).on('change', '.js-shipping-option', (e) => {
    e.preventDefault();

    const $form = $unavailableShippingWrapper.find('form');

    $.ajax({
      url: $form.attr('action'),
      method: 'POST',
      data: $form.serialize()
    })
      .then(() => {
        prestashop.emit('updateCart', {
          reason: {
            cart: prestashop.cart,
          },
          skip_unavailable_shipping_refresh: true
        });
      })
      .catch(error => {
        if ('responseJSON' in error && 'error' in error.responseJSON) {
          alert(error.responseJSON.error);
        } else {
          console.log(error);
        }
      });
  });

  function updateCartActions() {
    if (
      0 === $unavailableShippingWrapper.find('.js-shipping-option').length ||
      0 !== $unavailableShippingWrapper.find('.js-shipping-option:checked').length
    ) {
      return;
    }

    const $link = $('.cart-detailed-actions').find('a');

    if (0 !== $link.length) {
      $link.addClass('disabled');
    }

    const actionText = $unavailableShippingWrapper.data('disabled-actions-text');
    $('.cart-detailed-actions-text').html(`<span class="text-danger">${actionText}</span>`);
  }
});
