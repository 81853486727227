import $ from 'jquery';
import Swiper from 'swiper';
import Module from './Module';

export default class HomePageModule extends Module {

  constructor(args) {
    super(args);
    $(document).ready(() => {
      this.initSliders();
      this.initSwitcher();
    });
  }

  initSliders() {
    this.sliders = $('[data-home-slider-container]').map((index, item) => {
      const k = $(item).attr('data-home-slider-container');
      return new Swiper(`[data-home-slider-container="${k}"]`, {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          nextEl: '.js-home-products-arrow-right',
          prevEl: '.js-home-products-arrow-left',
          disabledClass: 'slider-products__arrow--disabled',
        },
        pagination: {
          el: `[data-home-slider-paginator="${k}"]`,
          type: 'bullets',
          clickable: true,
          bulletClass: 'slider-products__bullet',
          bulletActiveClass: 'slider-products__bullet--active',
        },
        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          500: {
            slidesPerView: 1,
          },
        },
      });
    });
  }

  initSwitcher() {
    $(document).on('click', '[data-home-slider-control]', (ev) => { this.switcherCallback(ev); });
  }

  /*
   * @param {Event} ev
   */
  switcherCallback(ev) {
    ev.preventDefault();
    const tar = $(ev.currentTarget);
    const key = tar.attr('data-home-slider-control');
    console.log(tar, key);
    $('[data-home-slider-control]').removeClass('home-products__sidebar-item-link--active');
    $(`[data-home-slider-control="${key}"]`).addClass('home-products__sidebar-item-link--active');
    $('[data-home-slider-key]').addClass('slider-products__slider-wrapper--inactive');
    $(`[data-home-slider-key="${key}"]`).removeClass('slider-products__slider-wrapper--inactive');
    this.updateSliders();
  }

  updateSliders() {
    if (this.sliders instanceof Array) {
      this.sliders.forEach((slider) => {
        if (slider instanceof Swiper) {
          slider.update();
        }
      });
    } else if (this.sliders instanceof $) {
      this.sliders.each((i, slider) => {
        if (slider instanceof Swiper) {
          slider.update();
        }
      });
    } else if (this.sliders instanceof Swiper) {
      this.sliders.update();
    }
  }

}
