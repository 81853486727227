import $ from "jquery";

let spinnerQty = $('.js-order-quantity');
let removeProductLink = $('.js-order-remove');

$.each(spinnerQty, function (index, spinner) {
  $(spinner).TouchSpin({
    buttondown_class: 'btn js-touchspin',
    buttonup_class: 'btn js-touchspin'
  }).on('change', function () {
    updateView($(this));
  });
});

removeProductLink.on('click', function (e) {
  e.preventDefault();
  updateView($(this), $(this).attr('href'));
});

function updateView(selector, url = null) {
  let totals = $('#totals');
  let product = selector.closest('.product-line-grid');
  let quantityContainer = product.find('.quantity-container');
  let quantityInput = quantityContainer.find('input');
  let totalPrice = product.find('.js-total-price');

  if(url === null) {
    url = quantityContainer.data('action');
  }

  $.ajax({
    method: 'POST',
    url: url,
    dataType: 'json',
    data: {
      quantity: quantityInput.val(),
      quantityOld: quantityContainer.data('quantity-old'),
      quantityTotal: totals.data('quantity-total'),
      productsTotal: totals.data('products-total'),
      discountsTotal: totals.data('discounts-total'),
      shippingTotal: totals.data('shipping-total'),
      orderTotal: totals.data('order-total'),
    },
    success: function (response) {
      if(response.success) {
        let quantity = response.data.quantity;

        totals.replaceWith(response.data.html);
        quantityContainer.data('quantity-old', quantity);
        quantityInput.val(quantity);
        totalPrice.html(response.data.rowTotal);

        if(quantity < 1) {
          product.hide();
        }
      } else {
        alert(response.errors);
      }
    }
  });
}
