import FileUploaderManager from './components/FileUploaderManager';

/* global $, prestashop */
$(function () {
    const fileUploaderManager = new FileUploaderManager();

    $(document).on('submit', '#csv-add-to-cart-form', function (e) {
        e.preventDefault();
        const submitButton = $('button[name="submitCsv"]');
        submitButton.prop('disabled', true);

        let $form = $(this);
        $.ajax({
            url: $form.attr('action'),
            method: 'post',
            data: new FormData($form[0]),
            dataType: 'json',
            contentType: false,
            processData: false,
            success: function (response) {
                const alertWrapper = $('#csv-modal-alert');
                alertWrapper.removeClass(['alert-danger', 'alert-warning', 'alert-success']);

                if ('error' in response) {
                  alertWrapper.addClass('alert-danger').html(response.error).show();
                  setTimeout(function () {
                    submitButton.prop('disabled', false);
                  }, 500);
                } else {
                  const elements = [];
                  for (const [code, message] of Object.entries(response.result)) {
                    elements.push(`<div class="col-4 font-weight-bolder">${code}</div><div class="col-2">—</div> <div class="col-6">${message}</div>`);
                  }

                  alertWrapper
                    .html(`<div class="row">${elements.join('')}</div>`)
                    .addClass(response.success ? 'alert-success' : 'alert-warning')
                    .show();

                  prestashop.emit('updateCart', {
                    reason: {
                      cart: prestashop.cart,
                    }
                  });
                }

                $('#csv-add-to-cart-modal').on('hidden.bs.modal', function (e) {
                    $('#csv-modal-alert').hide();
                    submitButton.prop('disabled', false);
                });
            }
        });
    });
});
