$(document).ready(function () {
    const div = $('#vmap');

    if (div.length) {
        const countryCodes = div.data('active-regions').split(',');
        const countryElementPrefix = '#jqvmap1_';
        const regionColorActive = '#aaaaaa';
        const regionColorSelected = '#e2001a';
        const fillOpacityHovered = 1;
        const fillOpacity = 1;

        div.vectorMap({
            map: 'world_en',
            backgroundColor: 'transparent',
            borderColor: '#ababab',
            borderOpacity: 0.2,
            borderWidth: 1,
            color: '#eeeeee',
            selectedColor: regionColorSelected,
            hoverColor: regionColorSelected,
            normalizeFunction: 'polynomial',
            enableZoom: false,
            onLoad: function(e, map){
                $(countryCodes).each(function(index, code){
                    div.find(countryElementPrefix + code).css('fill', regionColorActive);
                });
            },
            onRegionOver: function(e, code, region){
                if ($.inArray(code, countryCodes) !== -1) {
                    div.find(countryElementPrefix + code).css({
                        'fill-opacity': 0.7,
                        fill: regionColorSelected,
                    });
                } else {
                    e.preventDefault();
                }
            },
            onRegionOut: function(e, code, region){
                if ($.inArray(code, countryCodes) !== -1) {
                    div.find(countryElementPrefix + code).css({
                        'fill-opacity': 1,
                        fill: regionColorActive,
                    });
                }
            },
            onRegionClick: function(e, code, region){
                if ($.inArray(code, countryCodes) !== -1) {
                    goToCountryDescription(div, code);
                } else {
                    e.preventDefault();
                }
            },
        });

        $(document).on('click', '.js-cms-map-back-button', function(){
            returnFromCountryDescription(div);
        });

        $(document).on('click', '.js-cms-map-countries-item', function(){
            const e = $(this),
                code = $(this).attr("data-country");

            goToCountryDescription(div, code);
        });

        $(document).on('mouseover', '.js-cms-map-countries-item', function(e){
            const t = $(this);
            const n = t.data("country");
            t.addClass("active");
            $(countryElementPrefix + n).css({
                "fill-opacity": fillOpacityHovered,
                fill: regionColorSelected
            });
        });

        $(document).on('mouseout', '.js-cms-map-countries-item', function(e){
            const t = $(this);
            const n = t.data("country");
            t.removeClass("active");
            $(countryElementPrefix + n).css({
                "fill-opacity": fillOpacity,
                fill: regionColorActive
            });
        });

        $(document).on('click', '.js-cms-map-become-a-reseller-btn', function(){
            let r = $('.js-cms-form-container').offset().top,
                s = $('html, body');
            s.stop().animate({
                scrollTop: r
            }, '500', 'swing', function() {});
        });
    }
});

$(document).on('submit', '#js-become-reseller', function (e) {
    e.preventDefault();
    let $jsCmsFormError = $('.js-cms-form-error');

    $.ajax({
        url: $(this).attr('action'),
        type: 'post',
        data: $(this).serialize(),
        dataType: 'json',
        success: function (response) {
            if (response.success) {
                $('#js-become-reseller').slideUp();
                $jsCmsFormError
                    .slideUp()
                    .removeClass('alert-danger')
                    .addClass('alert-success mb-0')
                    .html(response.message)
                    .slideDown();

                let r = $jsCmsFormError.offset().top - 40,
                    s = $('html, body');
                s.stop().animate({
                    scrollTop: r
                }, '500', 'swing', function() {});
            } else {
                let errors = '';
                $.each(response.errors, function(i, c){
                    errors += `${c} <br/>`;
                });
                $jsCmsFormError.html(errors).slideDown();

                let r = $jsCmsFormError.offset().top - 40,
                    s = $('html, body');
                s.stop().animate({
                    scrollTop: r
                }, '500', 'swing', function() {});
            }
        }
    });
});

function scrollToTopOfMapContent(){
    let r = jQuery(".cms-map__section").offset().top - 130,
        s = $("html, body");
    s.stop().animate({
        scrollTop: r
    }, "500", "swing", function() {})
}

function goToCountryDescription(div, code){
    div.addClass('active');
    div.find('g').addClass('active');
    div.find(`.jqvmap-region.active`).removeClass('active');
    div.find(`#jqvmap1_${code}`).addClass('active');
    $(`.js-cms-map-resellers-item:visible`).hide();
    $(`.js-cms-map-resellers-item[data-country=${code}]`).show();

    $(".js-cms-map-list-wrapper").animate({
        left: "0"
    }, 400, function() {
        $('.js-cms-map-countries').hide();
    });
    scrollToTopOfMapContent();
}

function returnFromCountryDescription(div){
    div.removeClass('active');
    div.find('g').removeClass('active');
    div.find(`.jqvmap-region.active`).removeClass('active');
    $('.js-cms-map-countries').show();

    $(".js-cms-map-list-wrapper").animate({
        left: "-100%"
    }, 400, function() {
        $(`.js-cms-map-resellers-item:visible`).hide();
    });
}
