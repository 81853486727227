/* global $, prestashop */
$(document).ready(function () {
  prestashop.blockcart = prestashop.blockcart || {};

  var showModal = prestashop.blockcart.showModal || function (modal) {
    var $body = $('body');
    $body.append(modal);
    $body.one('click', '#blockcart-modal', function (event) {
      if (event.target.id === 'blockcart-modal') {
        $(event.target).remove();
      }
    });
  };

  $(document).ready(function () {
    prestashop.on(
      'updateCart',
      function (event) {
        var refreshURL = $('.blockcart').data('refresh-url');
        var requestData = {};

        if(typeof event.resp !== 'undefined' && typeof event.resp.hasError !== 'undefined' && event.resp.hasError){
          if(Array.isArray(event.resp.errors)) {
            $('#blockcart-error-modal').modal('show').find('.modal-text').html(event.resp.errors.join("<br>"));
          }else{
            $('#blockcart-error-modal').modal('show').find('.modal-text').html(Object.values(event.resp.errors).join("<br>"));
          }

        } else {
          if (event && event.reason) {
            requestData = {
              id_product_attribute: event.reason.idProductAttribute,
              id_product: event.reason.idProduct,
              measurement_unit: event.reason.measurementUnit,
              action: event.reason.linkAction,
              actionSource: event.reason.actionSource || null,
            };
          }

          $.post(refreshURL, requestData).then(function (resp) {
            $('.blockcart').replaceWith($(resp.preview).find('.blockcart'));
            if (resp.modal) {
              showModal(resp.modal);
            }
          }).fail(function (resp) {
            prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: resp});
          });
        }
      }
    );
  });
});
